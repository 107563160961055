import React from 'react';
import styles from './new-application.module.scss'
import {useParams} from "react-router-dom";
import {MainSelectionCalc} from "features/main-selection-calc";
import {MainLeasingCalc} from "features/main-leasing-calc";
import {Box} from "@mui/material";
import {DashboardHeaderBackLink} from "widgets/dashboard-header-back-link";

export const NewApplication = () => {
  const {type} = useParams();
  const title = type == 'select' ? 'Новая заявка на подбор транспортного средства' : 'Новая заявка на лизинг'

  const calcBLock = () => {
    if (type == 'select') {
      return (<MainSelectionCalc title={'Оставьте заявку на подбор ТС'} typeCars={'main'} inDashoard={true} />)
    }
    if (type == 'leasing') {
      return (<MainLeasingCalc title={'Оставьте заявку на лизинг'} inDashoard={true} />)
    }
  }

  return (
    <Box className={styles.newAppCalc}>
      <DashboardHeaderBackLink link={'/dashboard/last'} title={title} />
      <Box className={styles.calcBox}>
        {calcBLock()}
      </Box>
    </Box>
  );
};
