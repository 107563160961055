import {Box} from "@mui/material";
import styles from './main-footer.module.scss';
export const MainFooter = () => {
  return (
   <Box id="contacts" className={styles.footerContainer} component="footer">
     <h3>Контакты</h3>
     <span>E-mail: info@tls-group.online</span>
   </Box>
  );
};
