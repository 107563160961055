import React, {useEffect, useState} from 'react';
import styles from './main-leasing-calc.module.scss';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import { TextInput } from 'shared/ui/text-input';
import { SelectInput } from 'shared/ui/select-input';
import { SliderInput } from 'shared/ui/slider-input';
import { FileInputCustom } from 'shared/ui/file-input-custom';
import { ButtonCustom } from 'shared/ui/button-custom';
import { useNavigate } from 'react-router-dom';
import CloseIcon from 'shared/assets/icons/close.svg';
import {apiClient, getUser} from "shared/api";
import {PhoneInput} from "shared/ui/phone-input/phone-input";
import {MuiTelInputInfo} from "mui-tel-input";

interface LeasingCalcProps {
  title: string;
  inDashoard?: boolean;
}

export const MainLeasingCalc = (props: LeasingCalcProps) => {
  const { title, inDashoard } = props;

  const [titleModalBtn, setTitleModalBtn] = useState<string>('Войти в личный кабинет');
  const [url, setUrl] = useState<string>('/auth');

  const [open, setOpen] = React.useState(false);
  const [modalTitle, setModalTitle] = React.useState('');
  const [modalDesc, setModalDesc] = React.useState('');
  const [user, setUser] = React.useState<any>();

  const [kp, setKp] = useState<any>();

  const [inn, setInn] = useState<string | number>('');
  const [bio, setBio] = useState<string | number>('');
  const [email, setEmail] = useState<string | number>('');
  const [phone, setPhone] = useState<string | undefined>('');

  const [agentInn, setAgentInn] = useState<string | number>('');
  const [agentBio, setAgentBio] = useState<string | number>('');
  const [agentEmail, setAgentEmail] = useState<string | number>('');
  const [agentPhone, setAgentPhone] = useState<string | number>('');

  const [quantity, setQuantity] = useState<string | number>('');
  const [revenue, setRevenue] = useState<string | number>('');

  const handleChangeInn = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInn(event.target.value);
    setInnErr(!event.target.value)
  }
  const handleChangeBio = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBio(event.target.value);
    setBioErr(!event.target.value)
  }
  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setEmailErr(!event.target.value)
  }
  // const handleChangePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setPhone(event.target.value);
  //   setPhoneErr(!event.target.value)
  // }
  const handleChangePhone = (value: string, info: MuiTelInputInfo) => {
    setPhone(value);
    setPhoneErr(!value)
  }
  const handleChangeQuantity = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuantity(event.target.value);
    setQuantityErr(!event.target.value)
  }
  const handleChangeRevenue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRevenue(event.target.value);
  }

  let navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
  };

  const marksPrice = [
    {
      value: 1000000,
      label: '1 млн',
    },
    {
      value: 25500000,
      label: '25.5 млн',
    },
    {
      value: 50000000,
      label: '50 млн',
    },
  ];
  const marksInitialPayment = [
    {
      value: 0,
      label: '0 %',
    },
    {
      value: 24.5,
      label: '24.5 %',
    },
    {
      value: 49,
      label: '49 %',
    },
  ];
  const marksTerm = [
    {
      value: 12,
      label: '12 мес',
    },
    {
      value: 35.5,
      label: '35.5 мес',
    },
    {
      value: 59,
      label: '59 мес',
    },
  ];

  const [isAgent, setIsAgent] = useState<boolean>();

  const [brandValueList, setBrandValueList] = useState([]);
  const [typeValueList, setTypeValueList] = useState([]);
  const [subTypeValueList, setSubTypeValueList] = useState([]);
  const [modelValueList, setModelValueList] = useState([]);
  const [vehicleParamsList, setVehicleParamsList] = useState([]);
  const [vehicleParamsMainList, setVehicleParamsMainList] = useState<any>([]);

  const fetchData = async () => {
    try {
      const response = await apiClient.get('/car-types/all');
      setTypeValueList(response.data && response.data.data);
    } catch (error) {
      console.error('Ошибка при загрузке данных: ', error);
    }
  };
  const userGetInfo = async ()=> {
    if (inDashoard) {
      const userInfo = await getUser();
      setUser(userInfo);
      setIsAgent(userInfo.agent)
      if (userInfo.agent) {
        setAgentBio(userInfo.bio)
        setAgentEmail(userInfo.email)
        setAgentPhone(userInfo.phone)
        setAgentInn(userInfo.inn)
      } else {
        setBio(userInfo?.bio)
        setEmail(userInfo?.email)
        setPhone(userInfo?.phone)
        setInn(userInfo?.inn)
      }
    }
  }
  useEffect(() => {
    if(inDashoard) {
      userGetInfo()
    }

    fetchData();
  }, []);

  const getBrandsForType = async (car_type: number | string) => {
      try {
        const data = { typeId: car_type };
        const response = await apiClient.post('/car-brands/type', data);
        setBrandValueList(response?.data?.data);
      } catch (error) {
        console.error('Ошибка при загрузке данных:', error);
      }
  }

  const getVehicleForBrand = async (brand: number | string, type: number | string) => {
    try {
      const data = {brandId: brand, typeId: type};
      const response = await apiClient.post('/vehicles/brandType', data);
      setModelValueList(response?.data?.data);
    } catch (error) {
      console.error('Ошибка при загрузке данных:', error);
    }
  }

  const getVehicleParams = async (model: number | string) => {
    try {
      const data = {modelId: model};
      const response = await apiClient.post('/vehicles/car_params', data);
      setVehicleParamsList(response?.data?.data);
      setVehicleParamsMainList(response?.data?.data);
    } catch (error) {
      console.error('Ошибка при загрузке данных:', error);
    }
  }

  const getVehicleSubtype = async (model: number | string) => {
    try {
      const data = {modelId: model};
      const response = await apiClient.post('/vehicles/subtype', data);
      setSubtypeMain(response?.data?.data);
    } catch (error) {
      console.error('Ошибка при загрузке данных:', error);
    }
  }

  const [car_type, setCar_type] = useState<number | string>('');
  const [subtypeMain, setSubtypeMain] = useState<number | string>('');
  const [brand, setBrand] = useState<number | string>('');
  const [model, setModel] = useState<number | string>('');

  const [bioErr, setBioErr] = useState(false)
  const [initialErr, setInitialErr] = useState(false)
  const [leasePeriodErr, setLeasePeriodErr] = useState(false)
  const [innErr, setInnErr] = useState(false)
  const [emailErr, setEmailErr] = useState(false)
  const [phoneErr, setPhoneErr] = useState(false)
  const [priceErr, setPriceErr] = useState(false)
  const [quantityErr, setQuantityErr] = useState(false)
  const [car_typeErr, setCar_typeErr] = useState(false)
  const [car_brandErr, setCar_brandErr] = useState(false)
  const [car_modelErr, setCar_modelErr] = useState(false)

  const [loading, setLoading] = useState(false)

  const handleChangeCar_type = (event: SelectChangeEvent<unknown>) => {
    setCar_type(event.target.value as number);
    getBrandsForType(event.target.value as number);
    setBrand('');
    setModel('');
    setCar_typeErr(false);
  };
  const handleChangeBrand = (event: SelectChangeEvent<unknown>) => {
    setBrand(event.target.value as number);
    getVehicleForBrand(event.target.value as number, car_type as number);
    setModel('');
    setCar_brandErr(false)
  };
  const handleChangeModel = (event: SelectChangeEvent<unknown>) => {
    setModel(event.target.value as number);
    getVehicleParams(event.target.value as number);
    getVehicleSubtype(event.target.value as number);
    setCar_modelErr(false)
  };

  const handleChangeKp = (event: any) => {
    setKp(event.target.files);
  };

  const conditionBrandDisable = () => {
    let condition: boolean;
    if (!car_type) {
      condition = true;
    } else {
      condition = false;
    }
    return condition;
  }

  const handleClickOpen = async () => {
    const formData = new FormData();

    const priceInput = document.querySelector<HTMLInputElement>('[name="price"]');
    const initialInput = document.querySelector<HTMLInputElement>('[name="initial_payment"]');
    const leasePeriodInput = document.querySelector<HTMLInputElement>('[name="lease_period"]');

    formData.append('type', 'leasing');

    if (inDashoard) {
      if (isAgent) {
        formData.append('agent_bio', agentBio?.toString());
        formData.append('agent_email', agentEmail?.toString());
        formData.append('agent_phone', agentPhone?.toString());
        formData.append('agent_inn', agentInn?.toString());
    }
    }



    if (email?.toString().length) {
      formData.append('email', email?.toString() || '')
    } else {
      setEmailErr(true)
    }
    if (phone?.toString().length) {
      formData.append('phone', phone?.toString() || '')
    } else {
      setPhoneErr(true)
    }
    if (bio?.toString().length) {
      formData.append('bio', bio?.toString() || '')
    } else {
      setBioErr(true)
    }
    if (inn?.toString().length) {
      formData.append('inn', inn?.toString()  || '')
    } else {
      setInnErr(true)
    }
    if (quantity?.toString().length) {
      formData.append('quantity', quantity?.toString() || '')
    } else {
      setQuantityErr(true)
    }

    if (revenue?.toString().length) {
      formData.append('revenue', revenue?.toString() || '');
    }

    if (priceInput && priceInput.value) {
      formData.append('price', priceInput.value)
    } else {
      setPriceErr(true)
    }
    if (initialInput && initialInput.value) {
      formData.append('initial_payment', initialInput.value)
    } else {
      setInitialErr(true)
    }
    if (leasePeriodInput && leasePeriodInput.value) {
      formData.append('lease_period', leasePeriodInput.value)
    } else {
      setLeasePeriodErr(true)
    }

    if (car_type.toString().length) {
      formData.append('car_type', car_type.toString());
    } else {
      setCar_typeErr(true)
    }

    formData.append('car_subtype', subtypeMain.toString());

    if (brand.toString().length) {
      formData.append('car_brand', brand.toString());
    } else {
      setCar_brandErr(true)
    }
    if (model.toString().length) {
      formData.append('vehicle', model.toString());
    } else {
      setCar_modelErr(true)
    }

    if (kp && kp[0]) {
      formData.append('kp', kp[0]);
    } else {
      console.log('Файл отсутствует');
    }

    let validation = (
      !bioErr
      && !emailErr
      && !phoneErr
      && !quantityErr
      && inn
      && !car_typeErr
      && !car_brandErr
      && !car_modelErr
      && model?.toString().length
      && !innErr)

    if (validation) {
      try {
        setLoading(true);

        const url = inDashoard ? '/leasing-applications/new' : '/new-user-app'

        const response = await apiClient.post(url, formData);

        if (inDashoard) {
          navigate(-1);
        } else {
          setModalTitle('Благодарим за оформление заявки!');
          setModalDesc(
            'На Ваш E-mail отправлено письмо, содержащее в себе логин и пароль для личного кабинета. В нем будут храниться все ваши заявки.'
          );
          setTitleModalBtn('Войти в личный кабинет');
          setUrl('/auth');
          setOpen(true);
        }
      } catch (error:any) {
        if (error.response) {
          const errorMessage = error.response.data?.error?.message;

          if (errorMessage === 'User already exists') {
            setModalTitle('Ошибка! Вы уже зарегистрированы!');
            setModalDesc(
                'Создать новую или узнать статус текущих заявок можно в личном кабинете TLS Group.'
            );
            setTitleModalBtn('Войти в личный кабинет');
            setUrl('/auth');
          } else if (errorMessage === 'Missing required fields') {
            setModalTitle('Ошибка! Проверьте заполнение обязательных полей!');
            setModalDesc(
                'Для оформления заявки должны быть заполнены все обязательные поля!'
            );
            setTitleModalBtn('Продолжить');
            setUrl('');
          } else {
            setModalTitle('Ошибка!');
            setModalDesc('Произошла непредвиденная ошибка. Попробуйте снова позже.');
            setTitleModalBtn('Продолжить');
            setUrl('');
          }
        } else {
          setModalTitle('Ошибка!');
          setModalDesc('Произошла непредвиденная ошибка. Попробуйте снова позже.');
          setTitleModalBtn('Продолжить');
          setUrl('');
        }
        setOpen(true);
      } finally {
        setLoading(false);
      }
    }

  };

  return (
    <Box component="form" className={styles.calcContainer}>
      <Box className={styles.title}>{title}</Box>
      <Box className={`${styles.inn} ${styles.small}`}>
        <TextInput onChange={handleChangeInn} value={inn} error={innErr} name={'inn'} type={'number'} placeholder={'Введите ИНН'} required/>
      </Box>
      <Box className={`${styles.revenue} ${styles.small}`}>
        <TextInput name={'revenue'} onChange={handleChangeRevenue} value={revenue} type={'number'} placeholder={'Введите выручку в год'}/>
      </Box>
      {(!inDashoard || (inDashoard && isAgent)) && (
          <>
            <Box className={`${styles.bio} ${styles.big}`}>
              <TextInput onChange={handleChangeBio} value={bio} error={bioErr} name={'bio'} type={'text'}
                         placeholder={'Введите ФИО'} required/>
            </Box>
            <Box className={`${styles.email} ${styles.small}`}>
              <TextInput onChange={handleChangeEmail} value={email} error={emailErr} name={'email'} type={'email'} required
                         placeholder={'Введите E-mail'}/>
            </Box>
            <Box className={`${styles.tel} ${styles.small}`}>
              <PhoneInput onChange={handleChangePhone} value={phone} error={phoneErr} name={'phone'} required
                           placeholder={'Введите телефон'}/>
            </Box>
          </>
        )
      }
      <Box className={`${styles.quantity} ${styles.small}`}>
        <TextInput onChange={handleChangeQuantity} value={quantity} error={quantityErr} required name={'quantity'}
                   type={'number'} placeholder={'Введите кол-во ТС'}/>
      </Box>
      <Box className={`${styles.ts} ${styles.small}`}>
        <SelectInput
            name={'car_type'}
            value={car_type}
            required
            error={car_typeErr}
            onChange={handleChangeCar_type}
            placeholder={'Выберите ТС'}
        >
          {
            typeValueList.map((item: { id: number, name: string, documentId: string }) => (
                (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)
            ))
          }
        </SelectInput>
      </Box>
      <Box className={`${styles.brand} ${styles.small}`}>
        <SelectInput
            disabled={conditionBrandDisable()}
            value={brand}
            required
            error={car_brandErr}
            onChange={handleChangeBrand}
            placeholder={'Выберите марку'}
        >
          {brandValueList.map((item: { id: number, name: string, documentId: string }) =>
              (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)
          )}
        </SelectInput>
      </Box>
      <Box className={`${styles.payment} ${styles.small}`}>
        <SelectInput
            disabled={!brand}
            value={model}
            required
            error={car_modelErr}
            onChange={handleChangeModel}
            placeholder={'Выберите модель'}
        >
          {
            modelValueList.map((item: { id: number, name: string, documentId: string }) => (
                (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)
            ))
          }
        </SelectInput>
      </Box>
      <Box className={`${styles.slider} ${styles.big}`}>
        <SliderInput
          name="price"
          title={'Выберите располагаемый бюджет на покупку, ₽'}
          marks={marksPrice}
          step={100000}
          defaultValue={5000000}
          min={1000000}
          max={50000000}
          symbol={'₽'}
        />
      </Box>
      <Box className={`${styles.slider} ${styles.big}`}>
        <SliderInput
          name="initial_payment"
          title={'Выберите первоначальный взнос, %'}
          marks={marksInitialPayment}
          step={0.1}
          defaultValue={10}
          min={0}
          max={49}
          symbol={'%'}
        />
      </Box>
      <Box className={`${styles.slider} ${styles.big}`}>
        <SliderInput
          name="lease_period"
          title={'Выберите срок лизинга'}
          marks={marksTerm}
          step={0.5}
          defaultValue={18}
          min={12}
          max={59}
          symbol={'мес'}
        />
      </Box>
      <Box className={`${styles.file} ${styles.big}`}>
        <FileInputCustom value={kp} onChange={handleChangeKp}
                         text={'Прикрепить файл с имеющимся предложением'}/>
      </Box>
      <Box className={`${styles.submitBtn} ${styles.big}`}>
        <ButtonCustom onClick={handleClickOpen} disabled={loading}>
          {loading ? 'Подождите...' : 'Оформить заявку на лизинг'}
        </ButtonCustom>
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title-leasing"
        aria-describedby="alert-dialog-description-leasing"
        sx={{
          '.MuiDialog-paper': {
            padding: '40px 40px 50px',
            borderRadius: '10px',
          }
        }}
      >
        <DialogTitle className={styles.modalTitle} id="alert-dialog-title-leasing">
          <span>{modalTitle}</span>
          <IconButton
            disableRipple
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 12,
              top: 12,
              fontSize: 18
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={styles.modalDescription} id="alert-dialog-description-leasing">
            <span>{modalDesc}</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions className={styles.modalButtonWrapper}>
          {!inDashoard && (
            <ButtonCustom className={styles.modalButton} onClick={() => {navigate(`${url}`); setOpen(false)}}>
              {titleModalBtn}
            </ButtonCustom>
          )}
        </DialogActions>
      </Dialog>

    </Box>
  );
};
