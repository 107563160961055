import React from 'react';
import {Link} from "react-router-dom";
import logo from "shared/assets/images/logo.png";
import {checkIfAdmin} from "shared/api";

interface LogoLinkProps{
    dashboard?: boolean,
    admin?: boolean,
}

export const LogoLink = (props: LogoLinkProps) => {
    const {dashboard, admin} = props;


    let url = '/'
    if (dashboard) {
        if (admin) {
            url = '/dashboard/admin/last'
        } else {
            url = '/dashboard/last'
        }
    }
  return (
    <Link to={url}>
      <img src={logo} alt="TLS Group"/>
    </Link>
  );
};
