import styles from './slider-input.module.scss'
import {Box, Slider, SliderProps, SliderThumb} from "@mui/material";
import {useState} from "react";
import ThumbComponentIcon  from 'shared/assets/icons/range_slider.svg'

interface SliderInputProps extends SliderProps {
  symbol?: string;
}
interface ThumbComponentProps extends React.HTMLAttributes<unknown> {}

export const SliderInput = ({title, min, symbol, defaultValue, ...props}:SliderInputProps) => {
  const [value, setValue] = useState(defaultValue);

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number);
  };

  function ThumbComponent(props: ThumbComponentProps) {
    const { children, ...other } = props;
    return (
      <SliderThumb {...other}>
        {children}
        <ThumbComponentIcon />
      </SliderThumb>
    );
  }

  return (
    <Box className={styles.sliderWrapper}>
      <span className={styles.titleUp}>
        {title}
      </span>
      <span className={styles.valueTitle}>
        {new Intl.NumberFormat('ru-RU').format(value as number)} {symbol}
      </span>
      <Box className={styles.sliderContainer}>
        <Slider
          className={styles.sliderCustom}
          onChange={handleSliderChange}
          slots={{ thumb: ThumbComponent }}
          value={typeof value === 'number' ? value : min as number}
          sx={{
            '.MuiSlider-mark': {
              display: 'none',
            },
            '.MuiSlider-markLabel': {
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '5px',
              letterSpacing: '0.02em',
              color: '#96A8B8',
              '&:nth-of-type(4)': {
                left: '4%!important',
              },
              '&:nth-of-type(8)': {
                left: '95%!important',
              },
            },
            '.MuiSlider-thumb': {
              width: '16px',
              height: '16px',
              backgroundColor: 'unset',
              '&:after': {
                content: 'none',
              },
              '&:before': {
                content: 'none',
              },
              '&:hover': {
                boxShadow: 'none',
              },
              '&:focus': {

              },
              '&.Mui-focused':{
                boxShadow: 'none',
              },
              '&.Mui-focusVisible':{
                boxShadow: 'none',
              },
              '&:active': {
                boxShadow: 'none',
              }
            },
            '.MuiSlider-rail': {
              height: '2px',
              backgroundColor: '#B5D4F9',
              borderRadius: 0,
            },
            '.MuiSlider-track': {
              height: '2px',
              backgroundColor: '#4794EF',
              border: 'none',
              borderRadius: 0,
            },
          }}
          {...props}
          min={min}
        />
      </Box>
    </Box>
  );
};
