import React, {useEffect, useState} from 'react';
import styles from './otp.module.scss';
import {Box, Dialog, DialogContent, DialogContentText, DialogTitle, IconButton} from "@mui/material";
import { MuiOtpInput } from 'mui-one-time-password-input'
import {Link, useNavigate} from "react-router-dom";
import CloseIcon from "shared/assets/icons/close.svg";
import {apiClient} from "shared/api";

export const Otp = () => {
  let navigate = useNavigate();

  const [modalDesc, setModalDesc] = useState('');
  const [otp, setOtp] = useState('')
  const [mail, setMail] = useState('')

  const resendMail = async () => {
    try {
      const response = await apiClient.post('/send-otp', {email: mail});

    } catch (error:any) {
      if (error.response) {
        const errorMessage = error.response.data?.error?.message;
        if (errorMessage === 'User not exists') {
          setModalDesc(
            'Пользователя с таким email адресом не существует.'
          );
        } else {
          setModalDesc(
            'Что-то пошло не так, попробуйте позже.'
          );
        }
      }
      setOpen(true)
    } finally {
      setOtp('');
    }
  }


  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (newValue:string) => {
    setOtp(newValue)
  }

  useEffect(() => {
    const mail = sessionStorage.getItem('mail-pass-restore');
    setMail(mail || '')
  }, [])


  const verifOtp = async () => {
    return await apiClient.post('/check-otp', {email: mail, otp: otp});
  }

  useEffect(() => {
      if (otp.length == 6) {

          verifOtp().then(response => {
              sessionStorage.setItem('otp-pass-restore', otp);
              navigate('/auth/restore_pass/new')

          }).catch(error => {
              setOtp('');
              if (error.response) {
                  const errorMessage = error.response.data?.error?.message;
                  if (errorMessage === 'Wrong OTP') {
                      setModalDesc(
                        'Введён недействительный код восстановления.'
                      );
                  } else {
                      setModalDesc('Произошла непредвиденная ошибка. Попробуйте снова позже.');
                  }
                  setOpen(true);
              }
          });
     }
  }, [otp.length])

  return (
    <Box className={styles.loginPage}>
      <Box className={styles.loginTitleBlock}>
        <h2 className={styles.loginTitle}>
          <span className={styles.loginTitleBlack}>Обновите пароль</span>
        </h2>
        <span className={styles.loginDesc}>Введите код, который мы вам отправили на адрес <span className={styles.descBlue}>
          {mail}
        </span></span>
      </Box>
      <Box className={styles.loginForm}>
        <Box className={styles.loginInputBlock}>
          <Box className={styles.otpInputBack}>
            <MuiOtpInput
                className={styles.otpInput} value={otp} length={6} onChange={handleChange}

                         sx={{
              '.MuiOutlinedInput-root': {
                borderRadius: '8px',
                // border: '1px solid #4794EF'
                backgroundColor: '#ECF3F6',
                width: '46px',
                height: '48px',
                fontSize: '24px',
                fontWeight: 500,
                lineHeight: '24px',
                letterSpacing: '0.15000000596046448px',
                color: '#001317',
              '&:hover': {
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: '#4794EF',
                  }
                },
                '&.Mui-focused': {
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: '#4794EF',
                    borderWidth: '1px',
                    boxShadow: '0px 0px 0px 4px #84DCF53D',

            }
                }
              },
              '&': {
                gap: '44px'
              },
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: '#4794EF',
              }
            }} />
          </Box>
        </Box>
        <Box className={styles.otpButtonBlock}>
          <Link className={styles.otpBtn} to={'/auth/restore_pass/mail'}>Назад</Link>
          <div className={styles.otpBtn} onClick={resendMail}>Отправить код повторно</div>
        </Box>
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-email-description"
        sx={{
          '.MuiDialog-paper': {
            padding: '30px 30px 50px',
            borderRadius: '10px',
            maxWidth: '503px'
          }
        }}
      >
        <DialogTitle className={styles.modalTitle} id="alert-dialog-title">
          <span>Ошибка!</span>
          <IconButton
            disableRipple
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 12,
              top: 12,
              fontSize: 18
            }}
          >
            <CloseIcon/>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={styles.modalDescription} id="alert-email-description">
            <span>{modalDesc}</span>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Box>
  );
};
