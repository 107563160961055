import { createBrowserRouter } from 'react-router-dom';
import { AppLayout } from 'app/layouts/app-layout';
import {LandingMain} from 'pages/landing-main';
import {LandingProduct} from 'pages/landing-product';
import { ROUTE_PATHS } from 'shared/model/constants/paths';
import {AuthLayout} from "app/layouts/auth-layout";
import {AgentRegister, Login, NewPass, Otp, RestorePass} from "pages/auth";
import {DashboardLayout} from "app/layouts/dashboard-layout";
import {AllApplications, LatestApplications, NewApplication, OpenApplication, OpenOffer} from "pages/dashboard";
import {Application} from "pages/application";
import {LatestAppAdmin, AllAppAdmin, OpenAppAdmin, OfferAdmin, AgentsAdmin} from "pages/dashboard-admin";
import {Offer} from "pages/offer";
import {MailLayout} from "app/layouts/mail-layout";
import {NotFound} from "pages/not-found";

export const router = createBrowserRouter([
  {
    path: ROUTE_PATHS.MAIN,
    element: <AppLayout />,
    errorElement: <NotFound />,
    children: [
      {
        path: ROUTE_PATHS.MAIN,
        element: <LandingMain />,
      },
      {
        path: ROUTE_PATHS.PRODUCT,
        element: <LandingProduct/>,
      },
    ]
  },
  {
    path: ROUTE_PATHS.AUTH,
    element: <AuthLayout />,
    errorElement: <NotFound />,
    children: [
      {
        path: ROUTE_PATHS.AUTH,
        element: <Login />,
      },
      {
        path: ROUTE_PATHS.RESTORE_PASS,
        element: <RestorePass />,
      },
      {
        path: ROUTE_PATHS.OTP,
        element: <Otp />,
      },
      {
        path: ROUTE_PATHS.NEW_PASS,
        element: <NewPass />,
      },
      {
        path: ROUTE_PATHS.REGISTER_AGENT,
        element: <AgentRegister />,
      },
    ]
  },
  {
    path: ROUTE_PATHS.APPLICATION,
    element: <MailLayout />,
    errorElement: <NotFound />,
    children: [
      {
        path: ROUTE_PATHS.FULL_APP,
        element: <Application />,
      },
      {
        path: ROUTE_PATHS.FULL_OFFER,
        element: <Offer />,
      },
    ]
  },
  {
    path: ROUTE_PATHS.DASHBOARD,
    element: <DashboardLayout />,
    errorElement: <NotFound />,
    children: [
      {
        path: ROUTE_PATHS.ALL_APP,
        element: <AllApplications />,
      },
      {
        path: ROUTE_PATHS.LAST_APP,
        element: <LatestApplications />,
      },
      {
        path: ROUTE_PATHS.NEW_APP,
        element: <NewApplication />,
      },
      {
        path: ROUTE_PATHS.OPEN_APP,
        element: <OpenApplication />,
      },
      {
        path: ROUTE_PATHS.OPEN_OFFER,
        element: <OpenOffer />,
      },

      {
        path: ROUTE_PATHS.ADMIN_LAST,
        element: <LatestAppAdmin />,
      },
      {
        path: ROUTE_PATHS.ADMIN_ALL,
        element: <AllAppAdmin />,
      },
      {
        path: ROUTE_PATHS.ADMIN_APP,
        element: <OpenAppAdmin />,
      },
      {
        path: ROUTE_PATHS.ADMIN_OFFER,
        element: <OfferAdmin />,
      },
      {
        path: ROUTE_PATHS.ADMIN_AGENTS,
        element: <AgentsAdmin />,
      },
    ]
  }

]);
