import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import CheckboxSelectedIcon from 'shared/assets/icons/checkbox_select.svg';
import CheckboxEmptyIcon from 'shared/assets/icons/сheckbox_empty.svg';
import {SvgIcon} from "@mui/material";

export const CheckboxInput = (props:CheckboxProps) => {
    return (
        <Checkbox
            icon={<SvgIcon component={CheckboxEmptyIcon} inheritViewBox sx={{fontSize:'24px'}} />}
            checkedIcon={<SvgIcon component={CheckboxSelectedIcon} inheritViewBox sx={{fontSize:'24px'}} />}
            sx={
                {
                    '&':{ padding: 0, paddingRight: '10px',backgroundColor: 'unset', color: 'rgba(0, 0, 0, 0)'},
                    '& .MuiSvgIcon-root': { fontSize: 24 },
                    '&:hover': {backgroundColor: 'unset', color: 'rgba(0, 0, 0, 0)'},
                    '&:focus': {backgroundColor: 'unset', color: 'rgba(0, 0, 0, 0)'},
                    '&:active': {backgroundColor: 'unset', color: 'rgba(0, 0, 0, 0)'},
                }
            }
            {...props}
        />
    );
};
