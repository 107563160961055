import React, {Suspense} from 'react';
import styles from './not-found.module.scss'
import {Box, Container} from "@mui/material";
import errorImg from 'shared/assets/images/notfound.png'
import {ButtonCustom} from "shared/ui/button-custom";
import {useNavigate} from "react-router-dom";
import {MainHeader} from "widgets/main-header";
import {Loader} from "shared/ui/loader";
import {MainFooter} from "widgets/main-footer";

export const NotFound = () => {
    let navigate = useNavigate()
    return (
        <Container
            className={styles.appContainer}
            maxWidth={false}
            sx={
                {
                    maxWidth: '1440px',
                    minHeight: '100vh',
                    '&': {
                        paddingLeft: '20px',
                        paddingRight: '20px',
                    }
                }}
        >
            <MainHeader />
            <Suspense fallback={<Loader />}>
                <Box className={styles.notFoundWrapper}>
                    <Box className={styles.notFoundDesc}>
                        <h1>Уупс....</h1>
                        <h3>Страница не найдена</h3>
                        <p>Эта страница не существует или была удалена!</p>
                        <ButtonCustom className={styles.btn} onClick={() => navigate('/')}>
                            Вернуться на главную
                        </ButtonCustom>
                    </Box>
                    <Box className={styles.notFoundImg}>
                        <img src={errorImg} alt="Ошибка"/>
                    </Box>
                </Box>
            </Suspense>
            <MainFooter />
        </Container>

    );
};
