import React from 'react';
import {Box} from "@mui/material";
import styles from './auth-right-bar.module.scss';
import authBackground from 'shared/assets/images/auth_background.png'

export const AuthRightBar = () => {
  return (
    <Box className={styles.authRightBar}>
      <img src={authBackground} alt="Авторизация" className={styles.authBackground}/>
    </Box>
  );
};
