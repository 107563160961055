import React, {useState} from 'react';
import styles from './restore-pass.module.scss';
import {Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton} from "@mui/material";
import {MailInput} from "shared/ui/mail-input";
import {ButtonCustom} from "shared/ui/button-custom";
import {useNavigate} from "react-router-dom";
import CloseIcon from "shared/assets/icons/close.svg";
import {apiClient} from "shared/api";

export const RestorePass = () => {
  let navigate = useNavigate();

  const [load, setLoad] = useState(false)
  const [mail, setMail] = useState<string>('')
  const [mailErr, setMailErr] = useState(false)
  const [modalDesc, setModalDesc] = useState('');

  const handleChangeMail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMail(event.target.value);
    setMailErr(false);
  }

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const goOtp = async () => {
    try {
      setLoad(true)
      const response = await apiClient.post('/send-otp', {email: mail});

      sessionStorage.setItem('mail-pass-restore', mail);
      navigate('/auth/restore_pass/otp');
    } catch (error:any) {
      if (error.response) {
        const errorMessage = error.response.data?.error?.message;
        if (errorMessage === 'User not exists') {
          setModalDesc(
            'Пользователя с таким email адресом не существует.'
          );
        } else {
          setModalDesc(
            'Что-то пошло не так, попробуйте позже.'
          );
        }
      }
      setOpen(true)
    } finally {
      setLoad(false)
    }
  }

  return (
    <Box className={styles.loginPage}>
      <Box className={styles.loginTitleBlock}>
        <h2 className={styles.loginTitle}>
          <span className={styles.loginTitleBlack}>Восстановление пароля</span>
        </h2>
        <span className={styles.loginDesc}>Укажите адрес электронной почты для восстановления пароля.</span>
      </Box>
      <Box className={styles.loginForm}>
        <Box className={styles.loginInputBlock}>
          <Box className={styles.loginInputBack}>
            <MailInput
                error={mailErr} value={mail} onChange={handleChangeMail}
                name="email" required disabled={load}
            />
          </Box>
        </Box>
        <Box className={styles.submitBtn}>
          <ButtonCustom disabled={load} onClick={goOtp}>
            {load ? 'Подождите...' : 'Продолжить'}
          </ButtonCustom>
        </Box>
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-email-description"
        sx={{
          '.MuiDialog-paper': {
            padding: '30px 30px 50px',
            borderRadius: '10px',
            maxWidth: '503px'
          }
        }}
      >
        <DialogTitle className={styles.modalTitle} id="alert-dialog-title">
          <span>Ошибка!</span>
          <IconButton
            disableRipple
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 12,
              top: 12,
              fontSize: 18
            }}
          >
            <CloseIcon/>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={styles.modalDescription} id="alert-email-description">
            <span>{modalDesc}</span>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Box>
  );
};
