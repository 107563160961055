import {Box} from "@mui/material";
import styles from './auth-left-bar.module.scss';
import {LogoLink} from "shared/ui/logo-link";
import {Link, useLocation} from "react-router-dom";

export const AuthLeftBar = ({children}: any) => {
  let location = useLocation();

  const currentUrlLogin = () => {
    return location.pathname  === '/auth' || '/auth/';
  }

  const currentUrlAgent = () => {
    return location.pathname  === '/auth/registry';
  }

  const conf = () => {
    const link = document.createElement("a");
    link.href = "/Политика_в_отношении_обработки_персональных_данных.pdf";
    link.download = "Политика в отношении обработки персональных данных.pdf";
    link.click();
  }

  return (
    <Box className={styles.authLeftBar}>
      <Box className={styles.Logo}>
        <LogoLink />
      </Box>
      <Box className={styles.authForm}>
        {children}
      </Box>
      <Box className={styles.authFooter}>
        {(currentUrlLogin() && !currentUrlAgent() &&
            <span className={styles.authFooterText}>Вы партнер?&nbsp;<span><Link className={styles.authLink} to={'/auth/registry'}>Зарегистрироваться</Link></span></span>
        )}
        {(currentUrlAgent() &&
            <div className={styles.agentFooter}>
                <span>TLS Group © 2024</span>
                <span className={styles.agentFooterLink} onClick={conf}>Политика конфиденциальности</span>
            </div>
        )}
      </Box>
    </Box>
  );
};
