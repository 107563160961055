import styles from './mail-input.module.scss'
import {IconButton, InputAdornment, OutlinedInput, OutlinedInputProps, SvgIcon} from "@mui/material";
import MailIcon from "shared/assets/icons/mail.svg";
import React from "react";

export const MailInput = (props:OutlinedInputProps) => {

  return (
    <OutlinedInput
      fullWidth
      placeholder="Электронная почта"
      type={"email"}

      startAdornment={
        <InputAdornment position="start">
          <IconButton
            disableRipple
            sx={{
              width: '24px',
              height: '24px'
            }}
            edge="end"
            className={styles.svgIcon}
          >
            <SvgIcon component={MailIcon} inheritViewBox sx={{fontSize:'19px'}} />
          </IconButton>
        </InputAdornment>
      }

      sx={{
        '&': {
          borderRadius: '7px',
          height: '46px',
          backgroundColor: '#ECF3F6',
          paddingLeft: '16px',

          '&': {
            height: '100%',
            maxHeight: '48px',
            backgroundColor: '#ECF3F6',
            color: '#001317',
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '21.19px',
            letterSpacing: '0.02em',
            borderRadius: '8px',
            '&.Mui-error': {
              backgroundColor: '#FFC5C5',
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: '#FF9D9D',
                borderWidth: '1px',
              }
            }
          },
          '.MuiFormHelperText-root.Mui-error':{
            marginLeft: '0!important',
            color: '#FF3A3A!important',
            fontSize: '10px',
            fontWeight: 500,
            lineHeight: '11.77px',
          },
          '&:hover': {
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: '#8AB6EF',
              borderWidth: '1px',
            }

          },
          '&:active': {
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: '#4794EF',
              borderWidth: '1px',
            }
          },
          '&:focus': {
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: '#4794EF',
              borderWidth: '1px',
            }
          },
          '&.Mui-focused':{
            '.MuiOutlinedInput-notchedOutline':{
              borderColor: '#4794EF',
              borderWidth: '1px',
            }
          },
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: '#B5D4F9',
            borderWidth: '1px',
            outline: 'none',
          },
          '.MuiInputBase-input': {
            border: 'none',
            outline: 'none',
            padding: '11px 12px',
            '::placeholder': {
              color: '#96A8B8',
              opacity: 1,
            }
          },
        },
      }}
      className={styles.textInput}
      {...props}
    />
  );
};
