import axios from 'axios';

const isDev = __MODE__ == 'development';

const apiUrl = isDev ? 'http://localhost:1338/api' : 'https://strapi.tls-group.online/api'
export const serverUrl = isDev ? 'http://localhost:1338' :  'https://tls-group.online'

export const getToken = () => {
    return localStorage.getItem('token') || sessionStorage.getItem('token');
};

export const getUser = async () => {
    const token = getToken();
    if (!token) return null;

    try {
        const response = await axios.get(`${apiUrl}/users/me`, {
            headers: { Authorization: `Bearer ${token}` },
        });
        return response.data;
    } catch (err) {
        console.error('Ошибка получения пользователя:', err);
        return null;
    }
};
export const checkIfAdmin = async () => {
    try {
        const response = await apiClient.post(`${apiUrl}/custom-check-role`);
        const role = response.data;

        if (role && role.data.role === 'Administrator') {
            return true;
        }
        return false;
    } catch (error) {
        console.error('Error fetching user data:', error);
        return false;
    }
};
export const getAgent = async () => {
    const token = getToken();
    if (!token) return null;

    try {
        const response = await axios.get(`${apiUrl}/agent/is-agent`, {
            headers: { Authorization: `Bearer ${token}` },
        });
        return response.data;
    } catch (err) {
        console.error('Ошибка получения пользователя:', err);
        return null;
    }
}

export const checkAuth = async () => {
    const token = getToken();
    if (token) {
        try {
            const response = await apiClient.post(`${apiUrl}/custom-check-role`);
            const role = response.data;

            if (role && role.data.role === 'Administrator') {
                window.location.href ='/dashboard/admin/last';
            }
            if (role && role.data.role === 'Authenticated') {
                window.location.href ='/dashboard/last';
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    }
}

export const apiClient = axios.create({
    baseURL: apiUrl,
    timeout: 5000,
});

apiClient.interceptors.request.use((config) => {
    const token = getToken();
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, (error) => Promise.reject(error));

apiClient.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            console.warn('Токен недействителен, выход из системы.');
            localStorage.removeItem('token');
            sessionStorage.removeItem('token');
            window.location.href ='/auth';
        }
        return Promise.reject(error);
    }
);
