import React, { useState } from 'react';
import styles from './login.module.scss';
import { Box, FormControlLabel } from '@mui/material';
import { ButtonCustom } from 'shared/ui/button-custom';
import { CheckboxInput } from 'shared/ui/checkbox-input';
import { PassInput } from 'shared/ui/pass-input';
import { MailInput } from 'shared/ui/mail-input';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {apiClient, checkAuth, checkIfAdmin} from "shared/api";

export const Login = () => {

  checkAuth()

  const navigate = useNavigate();

  const [mail, setMail] = useState('');
  const [pass, setPass] = useState('');
  const [remember, setRemember] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleInputChange = (setter: React.Dispatch<React.SetStateAction<string>>) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setter(e.target.value);
      setError(false);
    };

  const handleLogin = async () => {
    if (loading) return;

    try {
      if (!mail || !pass) {
        throw new Error('Введите логин и пароль!');
      }

      setLoading(true);

      const { data } = await apiClient.post('/auth/local', {
        identifier: mail,
        password: pass,
      });

      const tokenStorage = remember ? localStorage : sessionStorage;
      tokenStorage.setItem('token', data.jwt);

      const isAdmin = await checkIfAdmin();

      if (isAdmin) {
        navigate('/dashboard/admin/last');
      } else {
        navigate('/dashboard/last');
      }
    } catch (err: any) {
      console.error(err);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const formControlLabelStyles = {
    margin: 0,
    '.MuiFormControlLabel-label': {
      fontSize: '16px',
      fontWeight: 500,
      letterSpacing: '0.02em',
      color: '#96A8B8',
    },
  };

  return (
    <Box className={styles.loginPage}>
      <Box className={styles.loginTitleBlock}>
        <h2 className={styles.loginTitle}>
          <span className={styles.loginTitleBlack}>Войдите в</span>
          <span className={styles.loginTitleBlue}> личный кабинет</span>
        </h2>
        <span className={styles.loginDesc}>
          Введите адрес электронной почты и пароль.
        </span>
      </Box>

      <Box className={styles.loginForm}>
        <Box className={styles.loginInputBlock}>
          <Box className={styles.loginInputBack}>
            <MailInput
              error={error}
              value={mail}
              onChange={handleInputChange(setMail)}
            />
          </Box>
          <Box className={styles.loginInputBack}>
            <PassInput
              error={error}
              value={pass}
              onChange={handleInputChange(setPass)}
            />
          </Box>
        </Box>

        <Box className={styles.rememberBlock}>
          <FormControlLabel
            sx={formControlLabelStyles}
            control={
              <CheckboxInput
                name="remember"
                checked={remember}
                onChange={(e) => setRemember(e.target.checked)}
              />
            }
            label="Запомнить меня"
          />
          <Link className={styles.rememberLink} to="/auth/restore_pass/mail">
            Забыли пароль?
          </Link>
        </Box>

        <Box className={styles.submitBtn}>
          <ButtonCustom disabled={loading} onClick={handleLogin}>
            {loading ? 'Загрузка...' : 'Войти'}
          </ButtonCustom>
        </Box>
      </Box>
    </Box>
  );
};
