import React from "react";
import { Select, MenuItem, SelectProps } from "@mui/material";
import styles from "./select-input.module.scss";
import ArrowDown from 'shared/assets/icons/arrow_down.svg'

interface SelectInputProps extends Omit<SelectProps, "multiple"> {
  placeholder?: string;
}

export const SelectInput = ({placeholder, value, children, ...props}:SelectInputProps) => {
  return (
    <Select
      fullWidth
      className={styles.selectInput}
      displayEmpty
      variant='outlined'
      IconComponent={ArrowDown}
      value={value ?? ""}
      renderValue={(selected) =>
        selected === "" ? (
          <span className={styles.placeholder}>{placeholder}</span>
        ) : (
          React.Children.toArray(children)
            .filter(
              (child): child is React.ReactElement =>
                React.isValidElement(child) && child.props.value === selected
            )
            .map((child) => child.props.children)
        )
      }
      MenuProps={{ classes: { paper: styles.dropdownStyle }}}
      sx={{
          '&': {
            height: '100%',
            maxHeight: '50px',
            backgroundColor: '#ECF3F6',
            color: '#001317',
            fontSize: '18px',
            fontWeight: 500,
            lineHeight: '21.19px',
            letterSpacing: '0.02em',
            // zIndex: 1301,
            borderRadius: '10px',
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(0,0,0,0)',
              borderWidth: '1px',
              outline: 'none',
            },
              '&.Mui-error': {
                  backgroundColor: '#FFC5C5',
                  '.MuiOutlinedInput-notchedOutline': {
                      borderColor: '#FF9D9D',
                      borderWidth: '1px',
                  }
              }
          },
          '.MuiFormHelperText-root.Mui-error':{
              marginLeft: '0!important',
              color: '#FF3A3A!important',
              fontSize: '10px',
              fontWeight: 500,
              lineHeight: '11.77px',

          },

          '&:hover': {
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: '#CFE2E8',
              borderWidth: '1px',
            }

          },
          '&:active': {
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: '#73ADBD',
              borderWidth: '1px',
            }
          },
          '&.Mui-disabled': {
            // opacity: 0.5,
            '.MuiSelect-icon': {
              opacity: 0.3,
            },
            'span': {
              opacity: 0.7,
            },
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(0,0,0,0)',
              borderWidth: '1px',
            }
          },
          '&:focus': {
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: '#73ADBD',
              borderWidth: '1px',
            }
          },
          '&.Mui-focused':{
            '.MuiOutlinedInput-notchedOutline':{
              borderColor: '#73ADBD',
              borderWidth: '1px',
            }
          },
          '.MuiInputBase-input': {
            border: 'none',
            outline: 'none',
            padding: '15px 20px',
          },
        '.MuiSelect-select': {
          lineHeight: '25px',
          paddingRight: '40px!important',
        },
        '.MuiSelect-iconOutlined': {
          right: '20px',
          transition: 'all .3s'
        },
        '.MuiSelect-icon': {
          width: '20px',
          height: '20px',
          padding: '6.5px 0',
          top: '16px',
        }
        }
      }
      {...props}
    >
      {placeholder && (
        <MenuItem value="" disabled>
          {placeholder}
        </MenuItem>
      )}
      {children}
    </Select>
  );
};
