import React, {useEffect, useState} from 'react';
import styles from './application.module.scss'
import {useParams} from "react-router-dom";
import {Box, MenuItem, SelectChangeEvent} from "@mui/material";
import {DownloadButton} from "shared/ui/download-button";
import {FileInputCustom} from "shared/ui/file-input-custom";
import {TextInputSmall} from "shared/ui/text-input-small";
import {SelectInputSmall} from "shared/ui/select-input-small";
import {ButtonCustom} from "shared/ui/button-custom";
import {apiClient} from "shared/api";
import clsx from "clsx";

export const Application = () => {

    const {type, uid, oid} = useParams()

    const [dynamicField, setDynamicField] = useState<any>([]);
    const [appData, setAppData] = useState<any>([]);
    const [offerData, setOfferData] = useState<any>([]);
    const [loading, setLoading] = useState<any>(false);
    const [fillDisplay, setFillDisplay] = useState<any>(false);

    const [modelValueList, setModelValueList] = useState([]);
    const [quantity, setQuantity] = useState<any>([]);
    const [bounty, setBounty] = useState<any>([]);
    const [price, setPrice] = useState<any>([]);
    const [delivery_time, setDelivery_time] = useState<any>([]);

    // const [initial_payment, setInitial_payment] = useState<any>([]);
    const [sum_total, setSum_total] = useState<any>([]);
    const [month_pay, setMonth_pay] = useState<any>([]);
    // const [sum_diff, setSum_diff] = useState<any>([]);
    // const [rise_price, setRise_price] = useState<any>([]);
    // const [lease_period, setLease_period] = useState<any>([]);


    const postDataApp = async () => {
        try {
            if (type == 'select') {
                const data = {id: uid};
                const responseApp = await apiClient.post('/select-applications/app-fill', data);
                setAppData(responseApp?.data?.data?.application);
            } else {
                const data = {id: uid};
                const responseApp = await apiClient.post('/leasing-applications/app-fill', data);
                setAppData(responseApp?.data?.data?.application);
            }

        } catch (error) {
            console.error('Ошибка при отправке данных:', error);
        }
    };

    const postOfferStatus = async () => {
        try {
            if (type == 'select') {
                const data = {id: oid};
                const responseApp = await apiClient.post('/select-offers/app-offer', data);
                setOfferData(responseApp?.data?.data?.offer);
            } else {
                const data = {id: oid};
                const responseApp = await apiClient.post('/leasing-offers/app-offer', data);
                setOfferData(responseApp?.data?.data?.offer);
            }

        } catch (error) {
            console.error('Ошибка при отправке данных:', error);
        }
    };

    useEffect(() => {
        postDataApp();
        postOfferStatus()
    }, [])

    useEffect(() => {
        setDynamicField(appData?.car_params || [])
        if (appData?.car_subtype_id) {
            getBrandsForSubType(appData.car_subtype_id);
        }
    }, [appData])

    const handleFillOffer = async () => {

        const formData = new FormData();
        if (bounty.toString().length) {
            formData.append('bounty', bounty.toString())
        } else {
            setBountyErr(true)
        }

        if (oid) {
            formData.append('docId', oid.toString())
        }
        if (condition.toString().length) {
            formData.append('condition', condition.toString())
        } else {
            setConditionErr(true)
        }
        if (brand.toString().length) {
            formData.append('car_brand', brand.toString())
        } else {
            setBrandErr(true)
        }
        if (model.toString().length) {
            formData.append('car_model', model.toString())
        } else {
            setModelErr(true)
        }
        if (quantity.toString().length) {
            formData.append('quantity', quantity.toString())
        } else {
            setQuantityErr(true)
        }
        if (price.toString().length) {
            formData.append('price', price.toString())
        } else {
            setPriceErr(true)
        }
        if (available.toString().length) {
            formData.append('availability', available.toString())
        } else {
            setAvailableErr(true)
        }
        if (delivery_time.toString().length) {
            formData.append('delivery_time', delivery_time.toString())
        } else {
            setDelivery_timeErr(true)
        }
        if (city.toString().length) {
            formData.append('city', city.toString())
        } else {
            setCityErr(true)
        }

        if (month_pay.toString().length) {
            formData.append('month_pay', month_pay.toString())
        }
        // else {
        //     setMonth_payErr(true)
        // }
        if (sum_total.toString().length) {
            formData.append('sum_total', sum_total.toString())
        } else {
            setSum_totalErr(true)
        }
        // if (initial_payment.toString().length) {
        //     formData.append('initial_payment', initial_payment.toString())
        // } else {
        //     setInitial_paymentErr(true)
        // }
        // if (lease_period.toString().length) {
        //     formData.append('lease_period', lease_period.toString())
        // } else {
        //     setLease_periodErr(true)
        // }
        // if (sum_diff.toString().length) {
        //     formData.append('sum_diff', sum_diff.toString())
        // }
        // if (rise_price.toString().length) {
        //     formData.append('rise_price', rise_price.toString())
        // }

        if (kp && kp[0]) {
            formData.append('kp', kp[0]);
        } else {
            console.log('Файл отсутствует');
        }

        if (calc && calc[0]) {
            formData.append('calc', calc[0]);
        } else {
            console.log('Файл отсутствует');
        }

        try {
            setLoading(true)
            const data = formData;
            const url = type == 'select' ? '/select-offers/fill' : '/leasing-offers/fill';

            const responseOffer = await apiClient.post(url, data);
            setFillDisplay(false);
            window.location.reload();
        } catch (error) {
            console.error('Ошибка при отправке данных:', error);
            setFillDisplay(true);
        } finally {
            setLoading(false)
        }
    };


    const handleChangeQuantity = (event: React.ChangeEvent<HTMLInputElement>) => {
        setQuantity(event.target.value);
        setQuantityErr(!event.target.value)
    }

    const handleChangeBounty = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBounty(event.target.value);
        setBountyErr(!event.target.value)
    }

    const handleChangePrice = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPrice(event.target.value);
        setPriceErr(!event.target.value)
    }

    const handleChangeCity = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCity(event.target.value);
        setCityErr(!event.target.value)
    };

    const handleChangeDelivery_time = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDelivery_time(event.target.value);
        setDelivery_timeErr(!event.target.value)
    };

    // const handleChangeInitial_payment = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setInitial_payment(event.target.value);
    //     setInitial_paymentErr(!event.target.value)
    // }
    const handleChangeSum_total = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSum_total(event.target.value);
        setSum_totalErr(!event.target.value)
    }
    const handleChangeMonth_pay = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMonth_pay(event.target.value);
        setMonth_payErr(!event.target.value)
    }
    // const handleChangeLease_period = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setLease_period(event.target.value);
    //     setLease_periodErr(!event.target.value)
    // }
    // const handleChangeSum_diff = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setSum_diff(event.target.value);
    // }
    // const handleChangeRise_price = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setRise_price(event.target.value);
    // }


    const renderApp = () => {
        if (type == 'select') {
            return (
                <Box className={styles.appWrapper}>
                    <Box className={styles.appDesc}>
                        <span className={styles.appDescTitle}>Состояние</span>
                        <span className={styles.appDescValue}>{appData?.condition}</span>
                    </Box>
                    <Box className={styles.appDesc}>
                        <span className={styles.appDescTitle}>Cпособ покупки</span>
                        <span className={styles.appDescValue}>{appData?.pay_method}</span>
                    </Box>

                    {appData?.inn && (<Box className={styles.appDesc}>
                        <span className={styles.appDescTitle}>ИНН</span>
                        <span className={styles.appDescValue}>{appData?.inn}</span>
                    </Box>)}

                    <Box className={styles.appDesc}>
                        <span className={styles.appDescTitle}>Количество ТС</span>
                        <span className={styles.appDescValue}>{appData?.quantity}</span>
                    </Box>
                    <Box className={styles.appDesc}>
                        <span className={styles.appDescTitle}>Траснспортное средство</span>
                        <span className={styles.appDescValue}>{appData?.car_subtype}</span>
                    </Box>
                    <Box className={styles.appDesc}>
                        <span className={styles.appDescTitle}>Марка</span>
                        <span className={styles.appDescValue}>{appData?.car_brand}</span>
                    </Box>
                    <Box className={styles.appDesc}>
                        <span className={styles.appDescTitle}>Модель</span>
                        <span className={styles.appDescValue}>{appData?.vehicle}</span>
                    </Box>

                    {dynamicField?.map((field: any) => (
                        <Box key={field?.fieldName} className={styles.appDesc}>
                            <span className={styles.appDescTitle}>{field?.fieldName}</span>
                            <span className={styles.appDescValue}>{field?.fieldValue}</span>
                        </Box>
                    ))}

                    <Box className={styles.appDesc}>
                        <span className={styles.appDescTitle}>Бюджет на покупку</span>
                        <span className={styles.appDescValue}>{new Intl.NumberFormat('ru-RU').format((appData?.budget) as number)} ₽</span>
                    </Box>

                    {appData?.kp && (
                        <Box className={styles.appDesc}>
                            <span className={styles.appDescTitle}>Имеющееся предложение</span>
                            <DownloadButton fileUrl={appData?.kp?.url} title={appData?.kp?.name}/>
                        </Box>
                    )}

                </Box>
            )
        }
        if (type == 'leasing') {
            return (
                <Box className={styles.appWrapper}>
                    <Box className={styles.appDesc}>
                        <span className={styles.appDescTitle}>ИНН</span>
                        <span className={styles.appDescValue}>{appData?.inn}</span>
                    </Box>
                    <Box className={styles.appDesc}>
                        <span className={styles.appDescTitle}>Траснспортное средство</span>
                        <span className={styles.appDescValue}>{appData?.car_subtype}</span>
                    </Box>
                    <Box className={styles.appDesc}>
                        <span className={styles.appDescTitle}>Марка</span>
                        <span className={styles.appDescValue}>{appData?.car_brand}</span>
                    </Box>
                    <Box className={styles.appDesc}>
                        <span className={styles.appDescTitle}>Модель</span>
                        <span className={styles.appDescValue}>{appData?.vehicle}</span>
                    </Box>


                    <Box className={styles.appDesc}>
                        <span className={styles.appDescTitle}>Количество ТС</span>
                        <span className={styles.appDescValue}>{appData?.quantity}</span>
                    </Box>


                    <Box className={styles.appDesc}>
                        <span className={styles.appDescTitle}>Стоимость предмета лизинга</span>
                        <span className={styles.appDescValue}>{new Intl.NumberFormat('ru-RU').format((appData?.price) as number)} ₽</span>
                    </Box>
                    <Box className={styles.appDesc}>
                        <span className={styles.appDescTitle}>Первоначальный взнос</span>
                        <span className={styles.appDescValue}>{appData?.initial_payment} %</span>
                    </Box>
                    <Box className={styles.appDesc}>
                        <span className={styles.appDescTitle}>Срок лизинга</span>
                        <span className={styles.appDescValue}>{appData?.lease_period} мес.</span>
                    </Box>

                    {appData?.kp && (
                        <Box className={styles.appDesc}>
                            <span className={styles.appDescTitle}>Имеющееся предложение</span>
                            <DownloadButton fileUrl={appData?.kp?.url} title={appData?.kp?.name}/>
                        </Box>
                    )}
                </Box>
            )
        }
    }


    const [quantityErr, setQuantityErr] = useState(false)
    const [bountyErr, setBountyErr] = useState(false)
    const [delivery_timeErr, setDelivery_timeErr] = useState(false)
    const [priceErr, setPriceErr] = useState(false)
    const [cityErr, setCityErr] = useState(false)

    const [availableErr, setAvailableErr] = useState(false)
    const [modelErr, setModelErr] = useState(false)
    const [brandErr, setBrandErr] = useState(false)
    const [conditionErr, setConditionErr] = useState(false)

    const [initial_paymentErr, setInitial_paymentErr] = useState(false)
    const [sum_totalErr, setSum_totalErr] = useState(false)
    const [month_payErr, setMonth_payErr] = useState(false)
    const [lease_periodErr, setLease_periodErr] = useState(false)

    const [condition, setCondition] = useState<string>("");
    const [available, setAvailable] = useState<string>("");
    const [city, setCity] = useState<string>("");

    const [kp, setKp] = useState<any>();
    const [calc, setCalc] = useState<any>();

    const handleChangeKp = (event: any) => {
        setKp(event.target.files);
    };
    const handleChangeCalc = (event: any) => {
        setCalc(event.target.files);
    };

    const [subtypeMain, setSubtypeMain] = useState<number | string>('');

    const [brand, setBrand] = useState<number | string>("");
    const [brandValueList, setBrandValueList] = useState([]);
    const [model, setModel] = useState<number | string>("");


    const getBrandsForSubType = async (car_subtype: number | string) => {
        try {
            const response = await apiClient.post('/car-brands/subtype',{subtypeId: car_subtype})
            const data = await response;
            setBrandValueList(data.data.data || [])
        } catch (e) {
            console.log(e)
        }
    }

    const getVehicleForBrandAndSubType = async (brand: number | string, subtype: number | string) => {
        try {
            const response = await apiClient.post('/vehicles/brandSubType', {brandId: brand, subtypeId: subtype})
            const data = await response;
            setModelValueList(data.data.data || [])
        } catch (e) {
            console.log(e)
        }

    }


    const getVehicleSubtype = async (model: number | string) => {
        try {
            const response = await apiClient.post('/vehicles/subtype', {modelId: model})
            const data = await response;
            setSubtypeMain(data.data.data)
        } catch (e) {
            console.log(e)
        }
    }

    const handleChangeAvailable = (event: SelectChangeEvent<unknown>) => {
        setAvailable(event.target.value as string);
        setAvailableErr(false)
    };

    const handleChangeCondition = (event: SelectChangeEvent<unknown>) => {
        setCondition(event.target.value as string);
        setConditionErr(false)
    };
    const handleChangeBrand = (event: SelectChangeEvent<unknown>) => {

        setBrand(event.target.value as string);
        getVehicleForBrandAndSubType(event.target.value as number, appData.car_subtype_id);

        setModel('');
        setBrandErr(false)
    };
    const handleChangeModel = (event: SelectChangeEvent<unknown>) => {
        setModel(event.target.value as string);
        getVehicleSubtype(event.target.value as number);
        setModelErr(false)
    };

    useEffect(() => {
        setFillDisplay(!offerData.status_filled);
    }, [offerData])

    const renderOffer = () => {
        if (type == 'select') {
            return (
                <Box className={styles.offerBlock}>
                    <Box className={styles.offerDesc}>
                        <span className={styles.appDescTitle}>Укажите состояние</span>
                        <SelectInputSmall
                            value={condition}
                            onChange={handleChangeCondition}
                            error={conditionErr}
                            placeholder={'Выберите состояние'}
                        >
                            <MenuItem value='Новый'>Новый</MenuItem>
                            <MenuItem value='С пробегом'>С пробегом</MenuItem>
                        </SelectInputSmall>
                    </Box>
                    <Box className={styles.offerDesc}>
                        <span className={styles.appDescTitle}>Укажите ТС</span>
                        <TextInputSmall name={'car_type'} type={'text'} disabled value={appData.car_subtype}/>
                    </Box>
                    <Box className={styles.offerDesc}>
                        <span className={styles.appDescTitle}>Укажите марку</span>
                        <SelectInputSmall
                            value={brand}
                            required
                            error={brandErr}
                            onChange={handleChangeBrand}
                            placeholder={'Выберите марку'}
                        >
                            {brandValueList.map((item: { id: number, name: string, documentId: string }) =>
                                (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)
                            )}
                        </SelectInputSmall>
                    </Box>
                    <Box className={styles.offerDesc}>
                        <span className={styles.appDescTitle}>Укажите модель</span>
                        <SelectInputSmall
                            disabled={!brand}
                            value={model}
                            required
                            error={modelErr}
                            onChange={handleChangeModel}
                            placeholder={'Выберите модель'}
                        >
                            {
                                modelValueList.map((item: { id: number, name: string, documentId: string }) => (
                                    (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)
                                ))
                            }
                        </SelectInputSmall>
                    </Box>

                    <Box className={styles.offerDesc}>
                        <span className={styles.appDescTitle}>Укажите кол-во ТС</span>
                        <TextInputSmall onChange={handleChangeQuantity} value={quantity} error={quantityErr} required
                                        name={'quantity'}
                                        type={'number'} placeholder={'Введите кол-во ТС'}/>
                    </Box>
                    <Box className={styles.offerDesc}>
                        <span className={styles.appDescTitle}>Укажите стоимость</span>
                        <TextInputSmall onChange={handleChangePrice} value={price} error={priceErr} required
                                        name={'price'}
                                        type={'number'} placeholder={'Введите стоимость'}/>
                    </Box>

                    <Box className={styles.offerDesc}>
                        <span className={styles.appDescTitle}>Укажите наличие</span>
                        <SelectInputSmall
                            value={available}
                            onChange={handleChangeAvailable}
                            error={availableErr}
                            placeholder={'Выберите наличие'}
                        >
                            <MenuItem value='В наличии'>В наличии</MenuItem>
                            <MenuItem value='Под заказ'>Под заказ</MenuItem>
                        </SelectInputSmall>
                    </Box>

                    <Box className={styles.offerDesc}>
                        <span className={styles.appDescTitle}>Укажите срок поставки</span>
                        <TextInputSmall onChange={handleChangeDelivery_time} value={delivery_time}
                                        error={delivery_timeErr} required name={'delivery_time'}
                                        type={'text'} placeholder={'Введите срок поставки'}/>
                    </Box>

                    <Box className={styles.offerDesc}>
                        <span className={styles.appDescTitle}>Укажите ваш город</span>
                        <TextInputSmall onChange={handleChangeCity} value={city} error={cityErr} required name={'city'}
                                        type={'text'} placeholder={'Введите ваш город'}/>
                    </Box>

                    <Box className={styles.offerDesc}>
                        <span className={styles.appDescTitle}>Размер вознаграждения, %</span>
                        <TextInputSmall onChange={handleChangeBounty} value={bounty} error={bountyErr} required
                                        name={'bounty'}
                                        type={'number'} placeholder={'Введите размер вознаграждения'}/>
                    </Box>

                    <Box className={styles.offerDesc}>
                        <span className={styles.appDescTitle}>Прикрепите КП</span>
                        <FileInputCustom value={kp} onChange={handleChangeKp} sx={{height: '40px'}}
                                         text={'Прикрепить КП'}/>
                    </Box>

                    {fillDisplay && (
                        <Box className={styles.acceptBtn}>
                            <ButtonCustom disabled={loading} onClick={handleFillOffer}>
                                {loading ? 'Подождите...' : 'Отправить предложение'}
                            </ButtonCustom>
                        </Box>)}
                </Box>

            )
        }
        if (type == 'leasing') {
            return (
                <Box className={styles.offerBlock}>
                    {/*<Box className={styles.offerDesc}>*/}
                    {/*    <span className={styles.appDescTitle}>Укажите ТС</span>*/}
                    {/*    <TextInputSmall name={'car_type'} type={'text'} disabled value={appData.car_subtype}/>*/}
                    {/*</Box>*/}
                    {/*<Box className={styles.offerDesc}>*/}
                    {/*    <span className={styles.appDescTitle}>Укажите марку</span>*/}
                    {/*    <SelectInputSmall*/}
                    {/*        value={brand}*/}
                    {/*        required*/}
                    {/*        error={brandErr}*/}
                    {/*        onChange={handleChangeBrand}*/}
                    {/*        placeholder={'Выберите марку'}*/}
                    {/*    >*/}
                    {/*        {brandValueList.map((item: { id: number, name: string, documentId: string }) =>*/}
                    {/*            (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)*/}
                    {/*        )}*/}
                    {/*    </SelectInputSmall>*/}
                    {/*</Box>*/}
                    {/*<Box className={styles.offerDesc}>*/}
                    {/*    <span className={styles.appDescTitle}>Укажите модель</span>*/}
                    {/*    <SelectInputSmall*/}
                    {/*        disabled={!brand}*/}
                    {/*        value={model}*/}
                    {/*        required*/}
                    {/*        error={modelErr}*/}
                    {/*        onChange={handleChangeModel}*/}
                    {/*        placeholder={'Выберите модель'}*/}
                    {/*    >*/}
                    {/*        {*/}
                    {/*            modelValueList.map((item: { id: number, name: string, documentId: string }) => (*/}
                    {/*                (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)*/}
                    {/*            ))*/}
                    {/*        }*/}
                    {/*    </SelectInputSmall>*/}
                    {/*</Box>*/}
                    {/*<Box className={styles.offerDesc}>*/}
                    {/*    <span className={styles.appDescTitle}>Укажите кол-во ТС</span>*/}
                    {/*    <TextInputSmall onChange={handleChangeQuantity} value={quantity} error={quantityErr} required*/}
                    {/*                    name={'quantity'}*/}
                    {/*                    type={'number'} placeholder={'Введите кол-во ТС'}/>*/}
                    {/*</Box>*/}
                    {/*<Box className={styles.offerDesc}>*/}
                    {/*    <span className={styles.appDescTitle}>Укажите стоимость</span>*/}
                    {/*    <TextInputSmall  onChange={handleChangePrice} value={price} error={priceErr}*/}
                    {/*        name={'price'} type={'number'} required placeholder={'Введите стоимость'}/>*/}
                    {/*</Box>*/}


                    {/*<Box className={styles.offerDesc}>*/}
                    {/*    <span className={styles.appDescTitle}>Укажите срок лизинга</span>*/}
                    {/*    <TextInputSmall  onChange={handleChangeLease_period} value={lease_period} error={lease_periodErr}*/}
                    {/*                     name={'lease_period'} required type={'number'} placeholder={'Введите срок лизинга'}/>*/}
                    {/*</Box>*/}

                    {/*<Box className={styles.offerDesc}>*/}
                    {/*    <span className={styles.appDescTitle}>Укажите первонач. взнос</span>*/}
                    {/*    <TextInputSmall  onChange={handleChangeInitial_payment} value={initial_payment} error={initial_paymentErr}*/}
                    {/*        name={'initial_payment'} required type={'number'} placeholder={'Введите первонач. взнос'}/>*/}
                    {/*</Box>*/}



                    <Box className={styles.appDesc}>
                        <span className={styles.appDescTitle}>Траснспортное средство</span>
                        <span className={styles.appDescValue}>{appData?.car_subtype}</span>
                    </Box>
                    <Box className={styles.appDesc}>
                        <span className={styles.appDescTitle}>Марка</span>
                        <span className={styles.appDescValue}>{appData?.car_brand}</span>
                    </Box>
                    <Box className={styles.appDesc}>
                        <span className={styles.appDescTitle}>Модель</span>
                        <span className={styles.appDescValue}>{appData?.vehicle}</span>
                    </Box>


                    <Box className={styles.appDesc}>
                        <span className={styles.appDescTitle}>Количество ТС</span>
                        <span className={styles.appDescValue}>{appData?.quantity}</span>
                    </Box>


                    <Box className={styles.appDesc}>
                        <span className={styles.appDescTitle}>Стоимость предмета лизинга</span>
                        <span className={styles.appDescValue}>{new Intl.NumberFormat('ru-RU').format((appData?.price) as number)} ₽</span>
                    </Box>
                    <Box className={styles.appDesc}>
                        <span className={styles.appDescTitle}>Первоначальный взнос</span>
                        <span className={styles.appDescValue}>{appData?.initial_payment} %</span>
                    </Box>
                    <Box className={styles.appDesc}>
                        <span className={styles.appDescTitle}>Срок лизинга</span>
                        <span className={styles.appDescValue}>{appData?.lease_period} мес.</span>
                    </Box>



                    <Box className={styles.offerDesc}>
                        <span className={styles.appDescTitle}>Укажите общ. сумму лиз. плат.</span>
                        <TextInputSmall  onChange={handleChangeSum_total} value={sum_total} error={sum_totalErr}
                            name={'sum_total'} required type={'number'} placeholder={'Введите общ. сумму лиз...'}/>
                    </Box>
                    <Box className={styles.offerDesc}>
                        <span className={styles.appDescTitle}>Укажите размер ежемес. плат.</span>
                        <TextInputSmall  onChange={handleChangeMonth_pay} value={month_pay}
                            name={'month_pay'} type={'number'} placeholder={'Введите размер ежемес...'}/>
                    </Box>
                    {/*<Box className={styles.offerDesc}>*/}
                    {/*    <span className={styles.appDescTitle}>Укажите разницу</span>*/}
                    {/*    <TextInputSmall onChange={handleChangeSum_diff} value={sum_diff}*/}
                    {/*                    name={'sum_diff'} type={'number'} placeholder={'Введите разницу'}/>*/}
                    {/*</Box>*/}
                    {/*<Box className={styles.offerDesc}>*/}
                    {/*    <span className={styles.appDescTitle}>Укажите удорожание</span>*/}
                    {/*    <TextInputSmall onChange={handleChangeRise_price} value={rise_price}*/}
                    {/*                    name={'rise_price'} type={'number'} placeholder={'Введите удорожание'}/>*/}
                    {/*</Box>*/}
                    <Box className={styles.offerDesc}>
                        <span className={styles.appDescTitle}>Укажите ваш город</span>
                        <TextInputSmall onChange={handleChangeCity} value={city} error={cityErr}
                                        name={'city'} required type={'text'} placeholder={'Введите ваш город'}/>
                    </Box>

                    <Box className={styles.offerDesc}>
                        <span className={styles.appDescTitle}>Размер вознаграждения, %</span>
                        <TextInputSmall onChange={handleChangeBounty} value={bounty} error={bountyErr} required
                                        name={'bounty'}
                                        type={'number'} placeholder={'Введите размер вознаграждения'}/>
                    </Box>


                    <Box className={styles.offerDesc}>
                        <span className={styles.appDescTitle}>Прикрепите расчет по форме</span>
                        <FileInputCustom value={calc} onChange={handleChangeCalc} sx={{height: '40px'}}
                                         text={'Прикрепить расчет'}/>
                    </Box>
                    <Box className={styles.offerDesc}>
                        <span className={styles.appDescTitle}>Прикрепите КП</span>
                        <FileInputCustom value={kp} onChange={handleChangeKp} sx={{height: '40px'}}
                                         text={'Прикрепить КП'}/>
                    </Box>


                    {fillDisplay && (
                        <Box className={styles.acceptBtn}>
                            <ButtonCustom disabled={loading} onClick={handleFillOffer}>
                                {loading ? 'Подождите...' : 'Отправить предложение'}
                            </ButtonCustom>
                        </Box>)}
                </Box>
            )
        }
    };


    const isStock = (status: string) => {
        return status == 'В наличии'
    }
    const isOrder = (status: string) => {
        return status == 'Под заказ'
    }


    const renderFillOffer = () => {
        if (type == 'select') {
            return (
                <Box className={styles.offerBlock}>
                    <Box className={styles.appDesc}>
                        <span className={styles.appDescTitle}>Состояние</span>
                        <span className={styles.appDescValue}>{offerData?.condition}</span>
                    </Box>
                    <Box className={styles.appDesc}>
                        <span className={styles.appDescTitle}>Количество ТС</span>
                        <span className={styles.appDescValue}>{offerData?.quantity}</span>
                    </Box>
                    <Box className={styles.appDesc}>
                        <span className={styles.appDescTitle}>Траснспортное средство</span>
                        <span className={styles.appDescValue}>{offerData?.car_subtype}</span>
                    </Box>
                    <Box className={styles.appDesc}>
                        <span className={styles.appDescTitle}>Марка</span>
                        <span className={styles.appDescValue}>{offerData?.car_brand}</span>
                    </Box>
                    <Box className={styles.appDesc}>
                        <span className={styles.appDescTitle}>Модель</span>
                        <span className={styles.appDescValue}>{offerData?.vehicle}</span>
                    </Box>

                    <Box className={styles.appDesc}>
                        <span className={styles.appDescTitle}>Стоимость</span>
                        <span className={styles.appDescValue}>{new Intl.NumberFormat('ru-RU').format((offerData?.price) as number)} ₽</span>
                    </Box>

                    <Box className={styles.appDesc}>
                        <span className={styles.appDescTitle}>Наличие</span>
                        <span
                            className={clsx([`${styles.appDescValue}`, isStock(offerData?.availability) && `${styles.stock}`, isOrder(offerData?.availability) && `${styles.order}`])}>
              {offerData?.availability}
            </span>
                    </Box>
                    <Box className={styles.appDesc}>
                        <span className={styles.appDescTitle}>Срок поставки</span>
                        <span className={styles.appDescValue}>{offerData?.delivery_time}</span>
                    </Box>
                    <Box className={styles.appDesc}>
                        <span className={styles.appDescTitle}>Город</span>
                        <span className={styles.appDescValue}>{offerData?.city}</span>
                    </Box>

                    {offerData?.kp && (
                        <Box className={styles.appDesc}>
                            <span className={styles.appDescTitle}>Коммерческое предложение</span>
                            <DownloadButton fileUrl={offerData?.kp?.url} title={offerData?.kp?.name}/>
                        </Box>
                    )}
                </Box>
            )
        }
        if (type == 'leasing') {
            return (
                <Box className={styles.offerBlock}>
                    <Box className={styles.appDesc}>
                        <span className={styles.appDescTitle}>Траснспортное средство</span>
                        <span className={styles.appDescValue}>{appData?.car_subtype}</span>
                    </Box>
                    <Box className={styles.appDesc}>
                        <span className={styles.appDescTitle}>Марка</span>
                        <span className={styles.appDescValue}>{appData?.car_brand}</span>
                    </Box>
                    <Box className={styles.appDesc}>
                        <span className={styles.appDescTitle}>Модель</span>
                        <span className={styles.appDescValue}>{appData?.vehicle}</span>
                    </Box>


                    <Box className={styles.appDesc}>
                        <span className={styles.appDescTitle}>Количество ТС</span>
                        <span className={styles.appDescValue}>{appData?.quantity}</span>
                    </Box>


                    <Box className={styles.appDesc}>
                        <span className={styles.appDescTitle}>Стоимость предмета лизинга</span>
                        <span className={styles.appDescValue}>{new Intl.NumberFormat('ru-RU').format((appData?.price) as number)} ₽</span>
                    </Box>
                    <Box className={styles.appDesc}>
                        <span className={styles.appDescTitle}>Первоначальный взнос</span>
                        <span className={styles.appDescValue}>{appData?.initial_payment} %</span>
                    </Box>
                    <Box className={styles.appDesc}>
                        <span className={styles.appDescTitle}>Срок лизинга</span>
                        <span className={styles.appDescValue}>{appData?.lease_period} мес.</span>
                    </Box>

                    <Box className={styles.appDesc}>
                        <span className={styles.appDescTitle}>Общая сумма лизинговых платежей</span>
                        <span className={styles.appDescValue}>{new Intl.NumberFormat('ru-RU').format((offerData?.sum_total) as number)} ₽</span>
                    </Box>

                    {offerData?.month_pay &&(
                        <Box className={styles.appDesc}>
                        <span className={styles.appDescTitle}>Размер ежемесячного платежа</span>
                        <span className={styles.appDescValue}>{new Intl.NumberFormat('ru-RU').format((offerData?.month_pay) as number)} ₽</span>
                        </Box>
                        )}

                    {/*<Box className={styles.appDesc}>*/}
                    {/*    <span className={styles.appDescTitle}>Разница</span>*/}
                    {/*    <span className={styles.appDescValue}>{new Intl.NumberFormat('ru-RU').format((offerData?.sum_diff) as number)} ₽</span>*/}
                    {/*</Box>*/}
                    {/*<Box className={styles.appDesc}>*/}
                    {/*    <span className={styles.appDescTitle}>Удорожание</span>*/}
                    {/*    <span className={styles.appDescValue}>{offerData?.rise_price} %</span>*/}
                    {/*</Box>*/}
                    <Box className={styles.appDesc}>
                        <span className={styles.appDescTitle}>Город</span>
                        <span className={styles.appDescValue}>{offerData?.city}</span>
                    </Box>

                    {offerData?.calc && (
                        <Box className={styles.appDesc}>
                            <span className={styles.appDescTitle}>Расчет по форме</span>
                            <DownloadButton fileUrl={offerData?.calc?.url} title={offerData?.calc?.name}/>
                        </Box>
                    )}

                    {offerData?.kp && (
                        <Box className={styles.appDesc}>
                            <span className={styles.appDescTitle}>Коммерческое предложение</span>
                            <DownloadButton fileUrl={offerData?.kp?.url} title={offerData?.kp?.name}/>
                        </Box>
                    )}
                </Box>
            )
        }
    }

    return (
        <Box className={styles.offerWrapper}>
            <Box className={styles.offerInside}>
                <span className={styles.header}>{`Заявка № ${appData.id}`}</span>
                {renderApp()}
            </Box>

            <Box className={styles.offerInside}>
                <span className={styles.header}>Форма заполнения</span>
                {!fillDisplay ? renderFillOffer() : renderOffer()}
            </Box>
        </Box>
    );
};
