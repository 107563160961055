import React, {useEffect, useState} from 'react';
import styles from './latest-applications.module.scss'
import {Box} from "@mui/material";
import {ButtonCustom} from "shared/ui/button-custom";
import {Link, useNavigate} from "react-router-dom";
import { apiClient } from "shared/api/";
import clsx from "clsx";

export const LatestApplications = () => {
  let navigate = useNavigate();

  const getLastSelectApp = async () => {
    try {
      const response = await apiClient.get('/select-applications/last');
      setSelectLastApp(response.data && response.data.data);
    } catch (error) {
      console.error('Ошибка при получении данных: ', error);
    }
  };

  const getLastLeasingApp = async () => {
    try {
      const response = await apiClient.get('/leasing-applications/last');
      setLeasingLastApp(response.data && response.data.data);
    } catch (error) {
      console.error('Ошибка при получении данных: ', error);
    }
  };
  const isNew = (status:string) => {
    return status == 'Новая'
  }
  const isWork = (status:string) => {
    return status == 'В обработке'
  }
  const isDone = (status:string) => {
    return status == 'Выполнена'
  }
  const [lastSelectApp, setSelectLastApp] = useState([]);
  const [lastLeasingApp, setLeasingLastApp] = useState([]);
  const [selectEmpty, setSelectEmpty] = useState(true);
  const [leasingEmpty, setLeasingEmpty] = useState(true);

  useEffect(() => {
    getLastSelectApp();
    getLastLeasingApp();
  }, []);

  useEffect(() => {
    setSelectEmpty(lastSelectApp.length < 3)
  }, [lastSelectApp])

  useEffect(() => {
    setLeasingEmpty(lastLeasingApp.length < 3)
  }, [lastLeasingApp])

  return (
    <Box className={styles.latestApp}>
      <Box className={styles.latestCardWrapper}>
        <Box className={styles.latestCardHeader}>
          <span className={styles.latestHeaderTitle}>Подбор ТС</span>
          {!selectEmpty && (<Link className={styles.latestHeaderLink} to={'/dashboard/all/select'}>Смотреть все</Link>)}
          <ButtonCustom className={styles.btnMob} onClick={() => navigate('/dashboard/new/select')}>Создать новую заявку</ButtonCustom>
        </Box>
        <Box className={styles.latestCardBlock}>
          {lastSelectApp.map((app:any) => (
              <Box key={app.documentId} className={styles.latestCard}>
                <span className={styles.latestCardTitle}>{`Заявка № ${app.id}`}</span>
                <Box className={styles.latestCardDesc}>
                  <Box className={styles.latestCardDescRow}>
                    <span className={styles.cardDescTitle}>Транспортное средство</span>
                    <span className={styles.cardDescValue}>{app.car_subtype}</span>
                  </Box>
                  <Box className={styles.latestCardDescRow}>
                    <span className={styles.cardDescTitle}>Марка</span>
                    <span className={styles.cardDescValue}>{app.car_brand}</span>
                  </Box>
                  <Box className={styles.latestCardDescRow}>
                    <span className={styles.cardDescTitle}>Модель</span>
                    <span className={styles.cardDescValue}>{app.vehicle}</span>
                  </Box>
                  <Box className={styles.latestCardDescRow}>
                    <span className={styles.cardDescTitle}>Бюджет на покупку</span>
                    <span className={styles.cardDescValue}>{new Intl.NumberFormat('ru-RU').format((app?.budget) as number)} ₽</span>
                  </Box>
                  <Box className={styles.latestCardDescRow}>
                    <span className={styles.cardDescTitle}>Статус заявки</span>
                    <span className={clsx([
                      `${styles.cardDescValue}`,
                      isNew(app.status_app) && `${styles.new}`,
                      isWork(app.status_app) && `${styles.work}`,
                      isDone(app.status_app) && `${styles.done}`
                    ])}>{app.status_app}</span>
                  </Box>
                </Box>
                <ButtonCustom className={styles.btn} onClick={() => navigate(`/dashboard/app/select/${app.documentId}`)}>Перейти к заявке</ButtonCustom>
              </Box>
          ))}

          <Box className={styles.newCard}>
            <ButtonCustom className={styles.btn} onClick={() => navigate('/dashboard/new/select')}>Создать новую заявку</ButtonCustom>
          </Box>
        </Box>
        {!selectEmpty && (<Link className={styles.latestHeaderLinkMob} to={'/dashboard/all/select'}>Смотреть все</Link>)}
      </Box>
      <Box className={styles.latestCardWrapper}>
        <Box className={styles.latestCardHeader}>
          <span className={styles.latestHeaderTitle}>Лизинг</span>
          {!leasingEmpty && (<Link className={styles.latestHeaderLink} to={'/dashboard/all/leasing'}>Смотреть все</Link>)}
          <ButtonCustom className={styles.btnMob} onClick={() => navigate('/dashboard/new/leasing')}>Создать новую заявку</ButtonCustom>
        </Box>
        <Box className={styles.latestCardBlock}>
          {lastLeasingApp.map((app:any) => (
          <Box key={app.documentId} className={styles.latestCard}>
            <span className={styles.latestCardTitle}>Заявка № {app.id}</span>
            <Box className={styles.latestCardDesc}>
              <Box className={styles.latestCardDescRow}>
                <span className={styles.cardDescTitle}>Транспортное средство</span>
                <span className={styles.cardDescValue}>{app.car_subtype}</span>
              </Box>
              <Box className={styles.latestCardDescRow}>
                <span className={styles.cardDescTitle}>Марка</span>
                <span className={styles.cardDescValue}>{app.car_brand}</span>
              </Box>
              <Box className={styles.latestCardDescRow}>
                <span className={styles.cardDescTitle}>Модель</span>
                <span className={styles.cardDescValue}>{app.vehicle}</span>
              </Box>
              <Box className={styles.latestCardDescRow}>
                <span className={styles.cardDescTitle}>Стоимость предмета лизинга</span>
                <span className={styles.cardDescValue}>{new Intl.NumberFormat('ru-RU').format((app?.price) as number)} ₽</span>
              </Box>
              <Box className={styles.latestCardDescRow}>
                <span className={styles.cardDescTitle}>Статус заявки</span>
                <span
                    className={clsx([
                      `${styles.cardDescValue}`,
                      isNew(app.status_app) && `${styles.new}`,
                      isWork(app.status_app) && `${styles.work}`,
                      isDone(app.status_app) && `${styles.done}`
                    ])}
                >{app.status_app}</span>
              </Box>
            </Box>
            <ButtonCustom className={styles.btn} onClick={() => navigate(`/dashboard/app/leasing/${app.documentId}`)}>Перейти к заявке</ButtonCustom>
          </Box>
          ))
          }
          <Box className={styles.newCard}>
            <ButtonCustom className={styles.btn} onClick={() => navigate('/dashboard/new/leasing')}>Создать новую заявку</ButtonCustom>
          </Box>
        </Box>
        {!leasingEmpty && (<Link className={styles.latestHeaderLinkMob} to={'/dashboard/all/leasing'}>Смотреть все</Link>)}
      </Box>
    </Box>
  );
};
