import React from 'react';
import styles from  './dashboard-header-back-link.module.scss'
import {Box, IconButton, SvgIcon} from "@mui/material";
import ArrowIcon from "shared/assets/icons/arrow_left.svg";
import {useNavigate} from "react-router-dom";

interface DashboardHeaderBackLinkProps {
  link: any,
  title: string,
}

export const DashboardHeaderBackLink = (props:DashboardHeaderBackLinkProps) => {
  let navigate = useNavigate();
  const {link, title} = props;

  return (
    <Box className={styles.headerLink}>
      <IconButton
        disableRipple
        onClick={()=>navigate(link)}
        sx={{
          width: '40px',
          height: '40px'
        }}
        edge="end"
        className={styles.svgIcon}
      >
        <SvgIcon component={ArrowIcon} inheritViewBox sx={{fontSize:'18px'}} />

      </IconButton>
      <span className={styles.headerTitle}>
          {title}
        </span>
    </Box>
  );
};
