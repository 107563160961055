import React, {useEffect, useState} from 'react';
import styles from './agents-admin.module.scss'
import {
  Box,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import {DashboardHeaderBackLink} from "widgets/dashboard-header-back-link";
import GreenCheck from "shared/assets/icons/green_check.svg";
import {ButtonCustom} from "shared/ui/button-custom";
import {apiClient} from "shared/api";

export const AgentsAdmin = () => {

  const [agentsArr, setAgentsArr] = useState<any>([]);


  const getAgentsArr = async () => {
    try {
      const response:any = await apiClient.get('/agents');
      const data = Array.isArray(response.data.data) ? response.data.data : [];
      setAgentsArr(data);
    } catch (e) {
      console.log(e)
    }
  }


  useEffect(()=> {
    getAgentsArr()
  },[])



  const handleAgentAccept = async (id:number | string) => {
    try {
      const response:any = await apiClient.post('/new-agent/confirm', {id: id}).then(()=>{
        getAgentsArr()
      })
    } catch (e) {
      console.log(e)
    }
  }



  const renderHeaderRow = () => {
    return (
      <TableRow className={styles.tableHeadRow}>
        <TableCell className={`${styles.tableHeadCellFirst} ${styles.tableHeadCell}`}>ФИО</TableCell>
        <TableCell className={`${styles.tableHeadCell} ${styles.tableCellMobile}`} align="left">E-mail</TableCell>
        <TableCell className={`${styles.tableHeadCell} ${styles.tableCellMobile}`} align="left">Телефон</TableCell>
        <TableCell className={`${styles.tableHeadCellLast} ${styles.tableHeadCell}`} align="right"></TableCell>
      </TableRow>
    )
  }
  const [renderAgentRow, setRenderAgentRow] = useState()

  const renderRows = (rows:any) => {
    return (rows?.map((row:any) => (
      <TableRow
        className={styles.tableBodyRow}
        key={row.id}
        sx={{ '& td, & th': { border: 0 }, marginTop: '10px' }}
      >
        <TableCell className={`${styles.tableBodyCellFirst} ${styles.tableBodyCell}`} component="th" scope="row">
          {row.bio}
        </TableCell>
        <TableCell className={`${styles.tableBodyCell} ${styles.tableCellMobile}`} align="left">{row.email}</TableCell>
        <TableCell className={`${styles.tableBodyCell} ${styles.tableCellMobile}`} align="left">{row.phone}</TableCell>
        <TableCell className={`${styles.tableBodyCellLast} ${styles.tableBodyCell}`} align="right">
          {
            !row.confirmed ? (
              <Box className={styles.greenBtn} onClick={() => handleAgentAccept(row.id)}>
                <ButtonCustom>Подтвердить агента</ButtonCustom>
              </Box>
            ) : (
              <SvgIcon className={styles.greenCheck} component={GreenCheck} inheritViewBox sx={{fontSize:'18px'}} />
            )
          }
        </TableCell>
      </TableRow>
    )))
  }

  useEffect(()=> {
    setRenderAgentRow(renderRows(agentsArr))
  },[agentsArr])



  return (
    <Box className={styles.agentsPage}>
      <DashboardHeaderBackLink link={-1} title={'Список агентов TLC Group'} />
      <Box>
        <TableContainer component={Box} className={styles.tableContainer}>
          <Table className={styles.table} sx={{ minWidth: 1070 }} aria-label={'Список агентов TLC Group'}>
            <TableHead className={styles.tableHead}>
              {renderHeaderRow()}
            </TableHead>
            <TableBody className={styles.tableBody}>
              {agentsArr.length > 0 ? renderAgentRow : undefined}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};
