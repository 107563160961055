import React, {useEffect, useState} from 'react';
import styles from './new-pass.module.scss';
import {Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton} from "@mui/material";
import {PassInput} from "shared/ui/pass-input";
import {ButtonCustom} from "shared/ui/button-custom";
import {useNavigate} from "react-router-dom";
import {apiClient} from "shared/api";
import CloseIcon from "shared/assets/icons/close.svg";

export const NewPass = () => {
  let navigate = useNavigate()
  const [load, setLoad] = useState(false);
  const [otp, setOtp] = useState('');
  const [email, setEmail] = useState('');
  const [passFirstErr, setPassFirstErr] = useState(false);
  const [passSecondErr, setPassSecondErr] = useState(false);
  const [passFirst, setPassFirst] = useState<string>('');
  const [passSecond, setPassSecond] = useState<string>('');
  const [modalTitle, setModalTitle] = useState('');
  const [modalDesc, setModalDesc] = useState('');
  const [titleModalBtn, setTitleModalBtn] = useState<string>('Войти в личный кабинет');
  const [url, setUrl] = useState<string>('/auth');

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangePassFirst = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassFirst(event.target.value);
    setPassFirstErr(false);
  }

  const handleChangePassTwo = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassSecond(event.target.value);
    setPassSecondErr(false);
  }

  useEffect(()=> {
    const mail = sessionStorage.getItem('mail-pass-restore');
    const otpCode = sessionStorage.getItem('otp-pass-restore');
    setEmail(mail || '')
    setOtp(otpCode || '')
  }, [])

  const resetPass = async () => {

    if (passFirst == passSecond) {
      try {
        setLoad(true);
        const response = await apiClient.post('/change-pass', {email: email, otp: otp, pass: passSecond});

        sessionStorage.removeItem('mail-pass-restore');
        sessionStorage.removeItem('otp-pass-restore');

        setModalTitle('Вы успешно сменили пароль!');
        setModalDesc(
          'Перейдите на страницу входа в аккаунт, и авторизируйтесь с новым паролем.'
        );
        setTitleModalBtn('Войти в личный кабинет');
        setUrl('/auth');
        setOpen(true)
      } catch (error: any) {
        sessionStorage.removeItem('mail-pass-restore');
        sessionStorage.removeItem('otp-pass-restore');
        setModalTitle('Ошибка!');
        setModalDesc(
          'Что-то пошло не так, попробуйте ещё раз'
        );
        setTitleModalBtn('Сменить пароль');
        setUrl('/auth/restore_pass/mail');
        setOpen(true)
      } finally {
        setLoad(false)
      }
    } else {
      setPassFirstErr(true);
      setPassSecondErr(true);
    }
  }


  return (
    <Box className={styles.loginPage}>
      <Box className={styles.loginTitleBlock}>
        <h2 className={styles.loginTitle}>
          <span className={styles.loginTitleBlack}>Создайте новый пароль</span>
        </h2>
        <span className={styles.loginDesc}>Введите новый пароль для входа в систему.</span>
      </Box>
      <Box className={styles.loginForm}>
        <Box className={styles.loginInputBlock}>
          <Box className={styles.loginInputBack}>
            <PassInput
                placeholder={'Введите новый пароль'} required value={passFirst}
                onChange={handleChangePassFirst} error={passFirstErr}
            />
          </Box>
          <Box className={styles.loginInputBack}>
            <PassInput
                placeholder={'Повторите новый пароль'} required value={passSecond}
                onChange={handleChangePassTwo} error={passSecondErr}
            />
          </Box>
        </Box>
        <Box className={styles.submitBtn}>
          <ButtonCustom disabled={load} onClick={resetPass}>
            {load ? 'Подождите...' : 'Создать новый пароль'}
          </ButtonCustom>
        </Box>
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-email-description"
        sx={{
          '.MuiDialog-paper': {
            padding: '30px 30px 50px',
            borderRadius: '10px',
            maxWidth: '503px'
          }
        }}
      >
        <DialogTitle className={styles.modalTitle} id="alert-dialog-title">
          <span>{modalTitle}</span>
          <IconButton
            disableRipple
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 12,
              top: 12,
              fontSize: 18
            }}
          >
            <CloseIcon/>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={styles.modalDescription} id="alert-email-description">
            <span>{modalDesc}</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions className={styles.modalButtonWrapper}>
            <ButtonCustom className={styles.modalButton} onClick={() => {navigate(`${url}`); setOpen(false)}}>
              {titleModalBtn}
            </ButtonCustom>
        </DialogActions>
      </Dialog>

    </Box>
  );
};
