import React from 'react'
import { MuiTelInput, MuiTelInputProps } from 'mui-tel-input'
import styles from './phone-input.module.scss';

export const PhoneInput = (props:MuiTelInputProps) => {

  return (
      <MuiTelInput
        fullWidth
        hiddenLabel
        variant='outlined'
        className={styles.textInput}
        {...props}
        sx={{
          '&': {
            '.MuiInputBase-root': {
              height: '100%',
              maxHeight: '50px',
              backgroundColor: '#ECF3F6',
              color: '#001317',
              fontSize: '18px',
              fontWeight: 500,
              lineHeight: '21.19px',
              letterSpacing: '0.02em',
              paddingLeft: 0,
              borderRadius: '10px',
              '&.Mui-error': {
                backgroundColor: '#FFC5C5',
                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: '#FF9D9D',
                  borderWidth: '1px',
                }
              }
            },
            '.MuiFormHelperText-root.Mui-error':{
              marginLeft: '0!important',
              color: '#FF3A3A!important',
              fontSize: '10px',
              fontWeight: 500,
              lineHeight: '11.77px',

            },
            '.MuiInputBase-root:hover': {
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: '#CFE2E8',
                borderWidth: '1px',
              }

            },
            '.MuiInputBase-root:active': {
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: '#73ADBD',
                borderWidth: '1px',
              }
            },
            '.MuiInputBase-root:focus': {
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: '#73ADBD',
                borderWidth: '1px',
              }
            },
            '.MuiOutlinedInput-root.Mui-focused':{
              '.MuiOutlinedInput-notchedOutline':{
                borderColor: '#73ADBD',
                borderWidth: '1px',
              }
            },
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(0,0,0,0)',
              borderWidth: '1px',
              outline: 'none',
            },
            '.MuiInputBase-input': {
              border: 'none',
              outline: 'none',
              padding: '15px 20px',
              '::placeholder': {
                color: '#96A8B8',
                opacity: 1,
              }
            },
            '.MuiInputAdornment-root': {
              display: 'none'
            }
          },
          ...props.sx
        }}/>
  );
};
